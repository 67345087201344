import logo from "./logo.png";

import "./App.css";

export default function Batalgaajulah() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="App-Logo" />
                <p>
                    Таны хаяг амжилттай баталгаажлаа. Та одоо буцаад АПП руугаа
                    орж болно.
                </p>
                <p>
                    Your accout has been activated successfully. You can go back
                    to the app now.
                </p>
            </header>
        </div>
    );
}
