import logo from "./logo.png";
import "./App.css";

export default function Android() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="App-Logo" />
                <p>Click Here To Download Mondaa App</p>
                <a
                    href={
                        "https://drive.google.com/u/0/uc?id=1ItY1Bp3s0rqJc6jOnX66kMqbLPvrJCi9&export=download&confirm=t&uuid=364c6848-878c-48b5-b2aa-36d011b82283&at=AB6BwCBaE35hqgqrEsZyg366amHm:1698383886255"
                    }
                    download={true}
                    className="Button"
                >
                    Download
                </a>
            </header>
        </div>
    );
}
