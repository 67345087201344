import { BrowserRouter, Route, Routes } from "react-router-dom";
import Android from "./Android";
import Batalgaajulah from "./Batalgaajuulah";
import Amjiltgui from "./Amjiltgui";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/amjiltgui" element={<Amjiltgui />} />
                <Route path="/batalgaajulah" element={<Batalgaajulah />} />
                <Route path="/" element={<Android />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
