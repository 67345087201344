import logo from "./logo.png";

import "./App.css";

export default function Amjiltgui() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="App-Logo" />
                <p>Буруу хаяг байна. Та Линкээ шалгаад дахин оролдоно уу.</p>
                <p>
                    Your have incorrect URL or it has been expired, please try
                    again.
                </p>
            </header>
        </div>
    );
}
